import axiosApiInstance from '@/api/axios'

const urlBase = '/api/pages'
const urlBaseHowToBuy = '/api/how_to_buy'

const state = {
  aboutUsMenuLoading: false
}

const getters = {
  aboutUsMenuLoading: (state) => state.aboutUsMenuLoading
}

const mutations = {
  SET_ABOUT_US_MENU_LOADING(state, payload) {
    state.aboutUsMenuLoading = payload
  }
}

const actions = {
  // AboutUsMenu
  fetchAboutUsMenu({ dispatch }, { vm }) {
    vm.aboutUsMenuLoading = true
    axiosApiInstance
      .get(vm.apiBase)
      .then((res) => {
        vm.aboutUsMenuItems = res.data
      })
      .catch((err) => {
        dispatch('auth/setRequestError', { err }, { root: true })
      })
      .finally(() => {
        vm.aboutUsMenuLoading = false
      })
  },

  createAboutMenuItem({ commit, dispatch }, { vm, payload }) {
    const data = new FormData()

    for (const key in payload) {
      data.append(key, payload[key])
    }

    commit('SET_ABOUT_US_MENU_LOADING', true)
    axiosApiInstance
      .post(`${urlBase}/create`, data)
      .then(() => {
        vm.$router.push('/pages/about-us/pages')
      })
      .catch((err) => {
        dispatch('auth/setRequestError', { err }, { root: true })
      })
      .finally(() => {
        commit('SET_ABOUT_US_MENU_LOADING', false)
      })
  },

  fetchSingleAboutMenuItem({ commit, dispatch }, { vm, id }) {
    commit('SET_ABOUT_US_MENU_LOADING', true)
    axiosApiInstance
      .get(`${urlBase}/edit`, {
        params: {
          id
        }
      })
      .then((res) => {
        vm.aboutUsMenuEditForm.title_uz = res.data.title_uz
        vm.aboutUsMenuEditForm.title_ru = res.data.title_ru
        vm.aboutUsMenuEditForm.title_en = res.data.title_en
        vm.aboutUsMenuEditForm.description_ru = res.data.description_ru
        vm.aboutUsMenuEditForm.description_en = res.data.description_en
        vm.aboutUsMenuEditForm.description_uz = res.data.description_uz
      })
      .catch((err) => {
        dispatch('auth/setRequestError', { err }, { root: true })
      })
      .finally(() => {
        commit('SET_ABOUT_US_MENU_LOADING', false)
      })
  },

  updateSingleAboutMenuItem({ dispatch }, { vm, payload, id }) {
    const data = new FormData()

    for (const key in payload) {
      data.append(key, payload[key])
    }

    data.append('id', id)

    axiosApiInstance
      .post(`${urlBase}/update`, data)
      .then(() => {
        vm.$router.push('/pages/about-us/pages')

        vm.aboutUsMenuEditForm.title_uz = ''
        vm.aboutUsMenuEditForm.title_ru = ''
        vm.aboutUsMenuEditForm.title_en = ''
        vm.aboutUsMenuEditForm.description_ru = ''
        vm.aboutUsMenuEditForm.description_en = ''
        vm.aboutUsMenuEditForm.description_uz = ''
      })
      .catch((err) => {
        dispatch('auth/setRequestError', { err }, { root: true })
      })
  },

  deleteAboutMenuItem({ dispatch }, { vm, id }) {
    const data = new FormData()
    data.append('id', id)

    axiosApiInstance
      .post(`${urlBase}/delete`, data)
      .then(() => {
        vm.$emit('update')
      })
      .catch((err) => {
        dispatch('auth/setRequestError', { err }, { root: true })
      })
  },

  // How to buy step
  fetchHowToBuyData({ dispatch }, { vm }) {
    vm.aboutUsMenuLoading = true
    axiosApiInstance
      .get(vm.apiBase)
      .then((res) => {
        vm.howToBuyData = res.data
      })
      .catch((err) => {
        dispatch('auth/setRequestError', { err }, { root: true })
      })
      .finally(() => {
        vm.aboutUsMenuLoading = false
      })
  },

  createHowToBuyDataItem({ commit, dispatch }, { vm, payload }) {
    const data = new FormData()

    for (const key in payload) {
      data.append(key, payload[key])
    }

    commit('SET_ABOUT_US_MENU_LOADING', true)
    axiosApiInstance
      .post(`${urlBaseHowToBuy}/create`, data)
      .then(() => {
        vm.$router.push('/pages/about-us/how-to-buy')
      })
      .catch((err) => {
        dispatch('auth/setRequestError', { err }, { root: true })
      })
      .finally(() => {
        commit('SET_ABOUT_US_MENU_LOADING', false)
      })
  },

  fetchSingleHowToBuyStep({ dispatch }, { vm, id }) {
    vm.aboutUsMenuLoading = true
    axiosApiInstance
      .get(`${urlBaseHowToBuy}/edit`, {
        params: {
          id
        }
      })
      .then((res) => {
        vm.aboutUsMenuEditForm.sort = res.data.sort
        vm.aboutUsMenuEditForm.title_uz = res.data.title_uz
        vm.aboutUsMenuEditForm.title_ru = res.data.title_ru
        vm.aboutUsMenuEditForm.title_en = res.data.title_en
        vm.aboutUsMenuEditForm.content_ru = res.data.content_ru
        vm.aboutUsMenuEditForm.content_en = res.data.content_en
        vm.aboutUsMenuEditForm.content_uz = res.data.content_uz
      })
      .catch((err) => {
        dispatch('auth/setRequestError', { err }, { root: true })
      })
      .finally(() => {
        vm.aboutUsMenuLoading = false
      })
  },

  updateSingleHowToBuyStep({ dispatch }, { vm, payload, id }) {
    const data = new FormData()
    for (const key in payload) {
      data.append(key, payload[key])
    }
    data.append('id', id)

    axiosApiInstance
      .post(`${urlBaseHowToBuy}/update`, data)
      .then(() => {
        vm.$bvModal.hide(`editHowToBuyStepModal${vm.data.id}`)
        vm.$emit('update')

        vm.aboutUsMenuEditForm.sort = ''
        vm.aboutUsMenuEditForm.title_uz = ''
        vm.aboutUsMenuEditForm.title_ru = ''
        vm.aboutUsMenuEditForm.title_en = ''
        vm.aboutUsMenuEditForm.content_ru = ''
        vm.aboutUsMenuEditForm.content_en = ''
        vm.aboutUsMenuEditForm.content_uz = ''
      })
      .catch((err) => {
        dispatch('auth/setRequestError', { err }, { root: true })
      })
  }
}

export default {
  state,
  getters,
  mutations,
  actions
}
