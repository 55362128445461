import axiosApiInstance from '@/api/axios'
const urlBase = '/api/product'

const actions = {
  fetchProducts({ dispatch }, { vm }) {
    vm.productsLoading = true
    axiosApiInstance
      .get(vm.apiUrl)
      .then((res) => {
        vm.products = res.data.data
        vm.page = res.data.current_page
        vm.numberOfPages = parseInt(res.data.last_page)
        vm.perPage = res.data.per_page
      })
      .catch((err) => {
        dispatch('auth/setRequestError', { err }, { root: true })
      })
      .finally(() => {
        vm.productsLoading = false
      })
  },

  createProduct({ dispatch }, { vm, payload }) {
    const data = new FormData()

    for (const key in payload) {
      data.append(key, payload[key])
    }

    axiosApiInstance
      .post(`${urlBase}/create`, data)
      .then(() => {
        vm.$router.push('/products')
      })
      .catch((err) => {
        dispatch('auth/setRequestError', { err }, { root: true })
      })
  },

  fetchSingleProduct({ dispatch }, { vm, id }) {
    vm.productInfoLoading = true
    axiosApiInstance
      .get('/api/product/edit', {
        params: { id }
      })
      .then((res) => {
        vm.clearForm()

        vm.imgUrl = res.data.product.image
        vm.form.name = res.data.product.name
        vm.form.category_id = vm.localedCategories.find(
          (c) => c.id == res.data.product.category_id
        )
        vm.form.old_price = parseFloat(res.data.product.old_price)
        vm.form.price = parseFloat(res.data.product.price)

        vm.form.description_uz = res.data.product.description_uz
        vm.form.description_ru = res.data.product.description_ru
        vm.form.description_en = res.data.product.description_en
        vm.form.character_uz = res.data.product.character_uz
        vm.form.character_ru = res.data.product.character_ru
        vm.form.character_en = res.data.product.character_en

        vm.uploadedFiles = res.data.product.gallery
        vm.productFeatures = res.data.product.features
      })
      .catch((err) => {
        dispatch('auth/setRequestError', { err }, { root: true })
      })
      .finally(() => {
        vm.productInfoLoading = false
      })
  },

  updateSingleProduct({ dispatch }, { vm, payload }) {
    const data = new FormData()

    for (const key in payload) {
      data.append(key, payload[key])
    }

    vm.productInfoLoading = true
    axiosApiInstance
      .post(`${urlBase}/update`, data)
      .then(() => {
        vm.$refs[`editProductModal${vm.data.id}`].hide()
        vm.$emit('fetch-products')

        vm.clearForm()
      })
      .catch((err) => {
        dispatch('auth/setRequestError', { err }, { root: true })
      })
      .finally(() => {
        vm.productInfoLoading = false
      })
  }
}

export default { actions }
