import Vue from 'vue'
import VueRouter from 'vue-router'
import AuthRequired from '../utils/AuthRequired'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    component: () =>
      import(/* webpackChunkName: "home" */ '@/layouts/Main.vue'),
    redirect: '/dashboard',
    beforeEnter: AuthRequired,
    children: [
      {
        path: 'dashboard',
        component: () =>
          import(/* webpackChunkName: "dashboard" */ '@/views/dashboard')
      },
      {
        path: 'categories',
        component: () =>
          import(/* webpackChunkName: "categories" */ '@/views/categories')
      },
      {
        path: 'products',
        component: () =>
          import(/* webpackChunkName: "products" */ '@/views/products'),
        redirect: '/products/list',
        children: [
          {
            path: 'list',
            component: () =>
              import(
                /* webpackChunkName: "productsList" */ '@/views/products/ProductsList.vue'
              )
          },
          {
            path: 'create',
            component: () =>
              import(
                /* webpackChunkName: "productsCreate" */ '@/views/products/CreateProduct.vue'
              )
          }
        ]
      },
      {
        path: 'media',
        component: () =>
          import(/* webpackChunkName: "media" */ '@/views/media'),
        redirect: '/media/photos',
        children: [
          {
            path: 'photos',
            component: () =>
              import(
                /* webpackChunkName: "mediaPhotos" */ '@/views/media/photos'
              )
          },
          {
            path: 'photos/gallery',
            component: () =>
              import(
                /* webpackChunkName: "mediaPhotosGallery" */ '@/views/media/photos/PhotosAlbumGallery.vue'
              )
          },
          {
            path: 'videos',
            component: () =>
              import(
                /* webpackChunkName: "mediaVideos" */ '@/views/media/videos'
              )
          }
        ]
      },
      {
        path: 'blog',
        component: () => import(/* webpackChunkName: "blog" */ '@/views/blog'),
        redirect: '/blog/list',
        children: [
          {
            path: 'list',
            component: () =>
              import(
                /* webpackChunkName: "blogList" */ '@/views/blog/BlogList.vue'
              )
          },
          {
            path: 'create',
            component: () =>
              import(
                /* webpackChunkName: "blogCreate" */ '@/views/blog/BlogCreate.vue'
              )
          }
        ]
      },
      {
        path: 'partners',
        component: () =>
          import(/* webpackChunkName: "partners" */ '@/views/partners'),
        redirect: '/partners/list',
        children: [
          {
            path: 'list',
            component: () =>
              import(
                /* webpackChunkName: "partnersList" */ '@/views/partners/PartnersList.vue'
              )
          },
          {
            path: 'create',
            component: () =>
              import(
                /* webpackChunkName: "partnersCreate" */ '@/views/partners/PartnerCreate.vue'
              )
          }
        ]
      },
      {
        path: 'slider',
        component: () =>
          import(/* webpackChunkName: "slider" */ '@/views/slider'),
        redirect: '/slider/list',
        children: [
          {
            path: 'list',
            component: () =>
              import(
                /* webpackChunkName: "sliderList" */ '@/views/slider/SliderList.vue'
              )
          },
          {
            path: 'create',
            component: () =>
              import(
                /* webpackChunkName: "sliderCreate" */ '@/views/slider/SliderCreate.vue'
              )
          }
        ]
      },
      {
        path: 'works',
        component: () =>
          import(/* webpackChunkName: "works" */ '@/views/works'),
        redirect: '/works/list',
        children: [
          {
            path: 'list',
            component: () =>
              import(
                /* webpackChunkName: "worksList" */ '@/views/works/WorkList.vue'
              )
          },
          {
            path: 'create',
            component: () =>
              import(
                /* webpackChunkName: "worksCreate" */ '@/views/works/WorkCreate.vue'
              )
          }
        ]
      },
      {
        path: 'calculator',
        component: () =>
          import(/* webpackChunkName: "calculator" */ '@/views/calculator')
      },
      {
        path: 'pages',
        component: () =>
          import(/* webpackChunkName: "pages" */ '@/views/pages'),
        children: [
          {
            path: 'advantages',
            component: () =>
              import(
                /* webpackChunkName: "pagesAdvantages" */ '@/views/pages/Advantages'
              )
          },
          {
            path: 'advantages/create',
            component: () =>
              import(
                /* webpackChunkName: "pagesAdvantagesCreate" */ '@/views/pages/Advantages/AdvantagesCreate.vue'
              )
          },
          {
            path: 'our-clients',
            component: () =>
              import(
                /* webpackChunkName: "pagesOurClients" */ '@/views/pages/OurClients'
              )
          },
          {
            path: 'our-clients/create',
            component: () =>
              import(
                /* webpackChunkName: "pagesOurClientsCreate" */ '@/views/pages/OurClients/ClientCreate.vue'
              )
          },
          {
            path: 'about-us',
            component: () =>
              import(
                /* webpackChunkName: "pagesAboutUsPages" */ '@/views/pages/AboutUsMenu'
              ),
            children: [
              {
                path: 'pages',
                component: () =>
                  import(
                    /* webpackChunkName: "pagesAboutUsPages" */ '@/views/pages/AboutUsMenu/AboutUsPages'
                  )
              },
              {
                path: 'pages/create',
                component: () =>
                  import(
                    /* webpackChunkName: "pagesAboutUsPagesCreate" */ '@/views/pages/AboutUsMenu/AboutUsPages/AboutUsMenuCreate.vue'
                  )
              },
              {
                path: 'pages/edit/:id',
                component: () =>
                  import(
                    /* webpackChunkName: "pagesAboutUsPagesEdit" */ '@/views/pages/AboutUsMenu/AboutUsPages/AboutUsMenuEdit.vue'
                  )
              },
              {
                path: 'how-to-buy',
                component: () =>
                  import(
                    /* webpackChunkName: "pagesAboutUsHowToBuy" */ '@/views/pages/AboutUsMenu/HowToBuy'
                  )
              },
              {
                path: 'how-to-buy/create',
                component: () =>
                  import(
                    /* webpackChunkName: "pagesAboutUsHowToBuyCreate" */ '@/views/pages/AboutUsMenu/HowToBuy/HowToBuyStepCreate.vue'
                  )
              }
            ]
          },
          {
            path: 'homepage',
            component: () =>
              import(
                /* webpackChunkName: "pagesHomepage" */ '@/views/pages/HomePage'
              ),
            children: [
              {
                path: 'sections',
                component: () =>
                  import(
                    /* webpackChunkName: "pagesHomepageSections" */ '@/views/pages/HomePage/HomePageSections.vue'
                  )
              }
            ]
          }
        ]
      },
      {
        path: 'orders',
        component: () =>
          import(/* webpackChunkName: "orders" */ '@/views/orders')
      },
      {
        path: 'feedback',
        component: () =>
          import(/* webpackChunkName: "feedback" */ '@/views/feedback')
      },
      {
        path: 'users',
        component: () =>
          import(/* webpackChunkName: "users" */ '@/views/users.vue')
      }
    ]
  },
  {
    path: '/auth',
    component: () => import(/* webpackChunkName: "auth" */ '@/views/auth'),
    redirect: '/auth/login',
    children: [
      {
        path: 'login',
        component: () =>
          import(/* webpackChunkName: "authLogin" */ '@/views/auth/Login.vue')
      }
    ]
  },
  {
    path: '/error',
    component: () => import(/* webpackChunkName: "error" */ '@/views/Error')
  },
  {
    path: '*',
    component: () => import(/* webpackChunkName: "error" */ '@/views/Error')
  }
]

const router = new VueRouter({
  linkActiveClass: 'active',
  routes,
  mode: 'history',
  scrollBehavior(_to, _from, savedPosition) {
    if (savedPosition) {
      return savedPosition
    } else {
      return { x: 0, y: 0 }
    }
  }
})

export default router
