import photos from './photos'
import photoGallery from './photos/photoGallery'
import videos from './videos'

export default {
  state: {},
  getters: {},
  mutations: {},
  actions: {},
  modules: {
    photos,
    photoGallery,
    videos
  }
}
