import axiosApiInstance from '@/api/axios'

const state = () => ({
  orders: [],
  ordersData: {
    currentPage: 1,
    perPage: 1,
    total: 1
  }
})

const getters = {
  orders: (state) => state.orders
}

const mutations = {
  SET_ORDERS(state, payload) {
    state.orders = payload.data
    state.ordersData.currentPage = payload.current_page
    state.ordersData.perPage = payload.per_page
    state.ordersData.total = payload.last_page
  }
}

const actions = {
  async fetchPayments({ commit, dispatch }) {
    try {
      const res = await axiosApiInstance.get('/api/payments/orders')
      commit('SET_ORDERS', res.data)
    } catch (err) {
      dispatch('auth/setRequestError', { err }, { root: true })
    }
  }
}

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
}
