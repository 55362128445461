import axiosApiInstance, { axiosBaseUrl } from '@/api/axios'
const urlBase = '/api/our_works'

const state = {
  worksLoading: false
}

const getters = {
  worksLoading: (state) => state.worksLoading
}

const mutations = {
  setWorksLoading(state, payload) {
    state.worksLoading = payload
  }
}

const actions = {
  fetchWorks({ dispatch }, { vm }) {
    vm.worksLoading = true
    axiosApiInstance
      .get(vm.apiUrl)
      .then((res) => {
        vm.ourWorks = res.data.data
        vm.page = res.data.current_page
        vm.numberOfPages = parseInt(res.data.last_page)
        vm.perPage = res.data.per_page
      })
      .catch((err) => {
        dispatch('auth/setRequestError', { err }, { root: true })
      })
      .finally(() => {
        vm.worksLoading = false
      })
  },

  createWork({ commit, dispatch }, { vm, payload }) {
    const data = new FormData()

    for (const key in payload) {
      data.append(key, payload[key])
    }

    commit('setWorksLoading', false)
    axiosApiInstance
      .post(`${urlBase}/create`, data)
      .then(() => {
        vm.$router.push('/works')
      })
      .catch((err) => {
        dispatch('auth/setRequestError', { err }, { root: true })
      })
      .finally(() => {
        commit('setWorksLoading', false)
      })
  },

  fetchSingleWork({ dispatch }, { vm, id }) {
    vm.worksLoading = true
    axiosApiInstance
      .get(`${urlBase}/edit`, {
        params: {
          id
        }
      })
      .then((res) => {
        let data = res.data[0]

        vm.uploadedFiles = res.data[1]

        vm.imgUrl = data.main_image
        vm.date_of_complete = new Date(data.date_of_complete)
        vm.workEditForm.title_uz = data.title_uz
        vm.workEditForm.title_ru = data.title_ru
        vm.workEditForm.title_en = data.title_en

        vm.workEditForm.content_uz = data.content_uz
        vm.workEditForm.content_ru = data.content_ru
        vm.workEditForm.content_en = data.content_en
      })
      .catch((err) => {
        dispatch('auth/setRequestError', { err }, { root: true })
      })
      .finally(() => {
        vm.worksLoading = false
      })
  },

  // update blog
  updateSingleWork({ dispatch }, { vm, payload, id }) {
    const data = new FormData()

    for (const key in payload) {
      data.append(key, payload[key])
    }

    data.append('id', id)

    axiosApiInstance
      .post(`${urlBase}/update`, data)
      .then(() => {
        vm.$bvModal.hide(`editSingleWorkModal${vm.data.id}`)
        vm.$emit('update')

        vm.uploadedFiles = []
        vm.date_of_complete = new Date()
        vm.workEditForm.title_uz = ''
        vm.workEditForm.title_ru = ''
        vm.workEditForm.title_en = ''

        vm.workEditForm.content_uz = ''
        vm.workEditForm.content_ru = ''
        vm.workEditForm.content_en = ''

        vm.imgUploded = false
        vm.imgUrl = null
        vm.fileRecords = []
        vm.fileRecordsForUpload = []
        vm.fileRecordsForGallery = []
        vm.fileRecordsForUploadForGallery = []
      })
      .catch((err) => {
        dispatch('auth/setRequestError', { err }, { root: true })
      })
  },

  uploadGalleryImagesForWork({ dispatch }, { files, vm }) {
    const data = new FormData()

    for (let i = 0; i < files.length; i++) {
      data.append('files[' + i + ']', files[i].file)
    }
    axiosBaseUrl
      .post('/api/files/upload', data)
      .then((res) => {
        if (vm.uploadedFiles.length === 0) {
          vm.uploadedFiles = res.data
        } else {
          vm.uploadedFiles = [...vm.uploadedFiles, ...res.data]
        }

        this.fileRecordsForGallery = []
        this.fileRecordsForUploadForGallery = []
      })
      .catch((err) => {
        dispatch('auth/setRequestError', { err }, { root: true })
      })
  }
}

export default {
  state,
  getters,
  mutations,
  actions
}
