/* eslint-disable no-unused-vars */
import axiosApiInstance from '@/api/axios'
import { parseJson, findObjectInArray } from '@/utils'
const urlBase = '/api/calc_details'

const state = {
  singleCalcDetail: {},
  calcDetailsLoading: false,
  singleCalcLoading: false,
  jsondata: [],
  doorColors: {
    ids: [2, 13],
    interior: [],
    exterior: []
  },
  detailsWithNoImages: [5, 6, 15],
  hasJsonData: false
}

const getters = {
  singleCalcDetail: (state) => state.singleCalcDetail,
  calcDetailsLoading: (state) => state.calcDetailsLoading,
  singleCalcLoading: (state) => state.singleCalcLoading,
  jsondata: (state) => state.jsondata,
  doorColors: (state) => state.doorColors,
  detailsWithNoImages: (state) => state.detailsWithNoImages,
  hasJsonData: (state) => state.hasJsonData
}

const mutations = {
  SET_SINGLE_CALC_DETAIL(state, payload) {
    state.singleCalcDetail = payload
  },
  SET_CALC_DETAILS_LOADING(state, payload) {
    state.calcDetailsLoading = payload
  },
  SET_SINGLE_CALC_LOADING(state, payload) {
    state.singleCalcLoading = payload
  },
  SET_DOOR_COLORS(state, payload) {
    const ids = state.doorColors.ids
    state.doorColors.interior = findObjectInArray(payload, 'id', ids[0]).data
    state.doorColors.exterior = findObjectInArray(payload, 'id', ids[1]).data
  },
  SET_JSON_DATA(state, payload) {
    if (payload) {
      state.jsondata = parseJson(payload) || payload
      state.hasJsonData = true
    } else {
      state.jsondata = []
      state.hasJsonData = false
    }
  }
}

const actions = {
  createCalcDetail({ commit, state, dispatch }, { vm, payload }) {
    commit('SET_SINGLE_CALC_LOADING', true)

    const data = new FormData()
    for (const key in payload) {
      data.append(key, payload[key])
    }
    data.append(
      'json_data',
      Array.isArray(state.jsondata)
        ? JSON.stringify(state.jsondata)
        : state.jsondata
    )
    axiosApiInstance
      .post(`${urlBase}/create`, data)
      .then(() => {
        vm.$bvModal.hide('createCalcDetailModal')
        vm.$emit('update')
        vm.reset()
      })
      .catch((err) => {
        dispatch('auth/setRequestError', { err }, { root: true })
      })
      .finally(() => {
        commit('SET_JSON_DATA', [])
        commit('SET_SINGLE_CALC_LOADING', false)
      })
  },
  fetchCalcDetails({ commit, dispatch }, { vm }) {
    commit('SET_CALC_DETAILS_LOADING', true)
    axiosApiInstance
      .get(vm.apiUrl)
      .then((res) => {
        vm.calcDetails = res.data.data
        vm.page = res.data.current_page
        vm.numberOfPages = res.data.last_page
        vm.perPage = res.data.per_page
      })
      .catch((err) => {
        dispatch('auth/setRequestError', { err }, { root: true })
      })
      .finally(() => {
        commit('SET_CALC_DETAILS_LOADING', false)
      })
  },
  deleteCalcDetail({ commit, dispatch }, { vm, id }) {
    const data = new FormData()
    data.append('id', id)
    axiosApiInstance
      .post(`${urlBase}/delete`, data)
      .then(() => {
        vm.$emit('update')
        vm.addNotification(
          'error filled',
          'Success',
          `The Detail #${id} deleted`
        )
      })
      .catch((err) => {
        dispatch('auth/setRequestError', { err }, { root: true })
      })
  },
  // fetch single detail for edit
  async fetchSingleCalcDetail({ commit, dispatch }, id) {
    commit('SET_JSON_DATA', [])
    commit('SET_CALC_DETAILS_LOADING', true)

    try {
      const res = await axiosApiInstance.get(`${urlBase}/edit`, {
        params: { id }
      })
      const data = res.data.calc_detail
      commit('SET_SINGLE_CALC_DETAIL', data)
      commit('SET_JSON_DATA', data.json_data)
    } catch (err) {
      dispatch('auth/setRequestError', { err }, { root: true })
    } finally {
      commit('SET_CALC_DETAILS_LOADING', false)
    }
  },
  editSingleCalcDetail({ commit, state, dispatch }, { vm, payload }) {
    commit('SET_SINGLE_CALC_LOADING', true)
    const data = new FormData()
    for (const key in payload) {
      data.append(key, payload[key])
    }
    data.append(
      'json_data',
      Array.isArray(state.jsondata)
        ? JSON.stringify(state.jsondata)
        : state.jsondata
    )
    axiosApiInstance
      .post(`${urlBase}/update`, data)
      .then(() => {
        vm.$bvModal.hide(`editCalcDetailModal${vm.current}`)
        vm.$emit('update')
        vm.reset()
      })
      .catch((err) => {
        dispatch('auth/setRequestError', { err }, { root: true })
      })
      .finally(() => {
        commit('SET_JSON_DATA', [])
        commit('SET_SINGLE_CALC_LOADING', false)
      })
  },
  async fetchDoorColors({ commit, dispatch }) {
    let requirements = []
    try {
      for (const catId of [2, 13]) {
        const res = await axiosApiInstance.get(`${urlBase}/requirements`, {
          params: {
            calc_cat_id: catId,
            canvas_id: 11
          }
        })
        requirements.push({ id: catId, data: res.data })
      }
      commit('SET_DOOR_COLORS', requirements)
    } catch (err) {
      dispatch('auth/setRequestError', { err }, { root: true })
    }
  }
}

export default {
  state,
  getters,
  mutations,
  actions
}
