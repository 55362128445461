import axiosApiInstance from '@/api/axios'
const urlBase = '/api/file_manager'

const state = () => ({
  uploadedFiles: [],
  isLoading: false
})

const getters = {
  uploadedFiles: (state) => state.uploadedFiles,
  isLoading: (state) => state.isLoading
}

const mutations = {
  SET_UPLOADED_FILES(state, payload) {
    state.uploadedFiles = payload
  },
  CHANGE_UPLOADED_FILES(state, payload) {
    state.uploadedFiles = [...state.uploadedFiles, payload]
  },
  SET_LOADING(state, payload) {
    state.isLoading = payload
  },
  RESET(state) {
    state.uploadedFiles = []
    state.isLoading = false
  }
}

const actions = {
  async uploadFile({ commit, dispatch }, { file, id }) {
    commit('SET_LOADING', true)
    try {
      const data = new FormData()
      data.append('file', file)
      const res = await axiosApiInstance.post(`${urlBase}/upload`, data)
      commit('CHANGE_UPLOADED_FILES', { id, path: res.data.message })
    } catch (err) {
      dispatch('auth/setRequestError', { err }, { root: true })
    } finally {
      commit('SET_LOADING', false)
    }
  },
  async deleteFile({ commit, state, dispatch }, { file_path, id }) {
    commit('SET_LOADING', true)
    const data = new FormData()
    data.append('file_path', file_path)
    try {
      await axiosApiInstance.post(`${urlBase}/delete`, data)
      const uploadedFiles = state.uploadedFiles.filter((file) => file.id !== id)
      commit('SET_UPLOADED_FILES', uploadedFiles)
    } catch (err) {
      dispatch('auth/setRequestError', { err }, { root: true })
    } finally {
      commit('SET_LOADING', false)
    }
  }
}

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
}
