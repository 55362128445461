import axiosApiInstance from '@/api/axios'

const state = () => ({
  users: [],
  isLoading: false
})

const getters = {
  users: (state) => state.users,
  isLoading: (state) => state.isLoading
}

const mutations = {
  SET_USERS(state, payload) {
    state.users = payload
  },
  SET_LOADING(state, payload) {
    state.isLoading = payload
  }
}

const actions = {
  async fetchUsers({ commit, dispatch }) {
    try {
      commit('SET_LOADING', true)
      const res = await axiosApiInstance.get('/api/users/list')
      commit('SET_USERS', res.data)
    } catch (err) {
      dispatch('auth/setRequestError', { err }, { root: true })
    } finally {
      commit('SET_LOADING', false)
    }
  }
}

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
}
