import axiosApiInstance from '@/api/axios'

const state = {
  photosCreateLoading: false
}

const getters = {
  photosCreateLoading: (state) => state.photosCreateLoading
}

const mutations = {
  setPhotosCreateLoading(state, payload) {
    state.photosCreateLoading = payload
  }
}

const actions = {
  // create photoalbum
  createPhotoAlbum({ commit, dispatch }, { vm, payload }) {
    const data = new FormData()

    for (const key in payload) {
      data.append(key, payload[key])
    }

    commit('setPhotosCreateLoading', true)
    axiosApiInstance
      .post('/api/photos_album/create', data)
      .then(() => {
        vm.$refs['createPhotosAlbumModal'].hide()
        vm.$emit('update')

        vm.photosAlbumCreateForm.name_uz = ''
        vm.photosAlbumCreateForm.name_ru = ''
        vm.photosAlbumCreateForm.name_en = ''
        vm.fileRecords = []
        vm.fileRecordsForUpload = []
      })
      .catch((err) => {
        dispatch('auth/setRequestError', { err }, { root: true })
      })
      .finally(() => {
        commit('setPhotosCreateLoading', false)
      })
  },

  // delete photoalbum
  deletePhotoAlbum({ dispatch }, { vm, payload }) {
    const data = new FormData()

    data.append('id', payload)

    axiosApiInstance
      .post('/api/photos_album/delete', data)
      .then(() => {
        vm.$emit('update')
      })
      .catch((err) => {
        dispatch('auth/setRequestError', { err }, { root: true })
      })
  },

  // get photoalbum for edit
  fetchSinglePhotoAlbum({ dispatch }, { vm, id }) {
    axiosApiInstance
      .get('/api/photos_album/edit', {
        params: {
          id
        }
      })
      .then((res) => {
        vm.imgUrl = res.data.cover
        vm.photosAlbumEditForm.name_en = res.data.name_en
        vm.photosAlbumEditForm.name_ru = res.data.name_ru
        vm.photosAlbumEditForm.name_uz = res.data.name_uz
      })
      .catch((err) => {
        dispatch('auth/setRequestError', { err }, { root: true })
      })
  },

  // edit photoalbum
  editPhotoAlbum({ dispatch }, { vm, payload, id }) {
    const data = new FormData()

    for (const key in payload) {
      data.append(key, payload[key])
    }

    data.append('id', id)

    axiosApiInstance
      .post('/api/photos_album/update', data)
      .then(() => {
        vm.$bvModal.hide(`editPhotoAlbum${vm.uniqueId}Modal`)
        vm.$emit('update')

        vm.photosAlbumEditForm.name_uz = ''
        vm.photosAlbumEditForm.name_ru = ''
        vm.photosAlbumEditForm.name_en = ''
        vm.imgUploded = false
        vm.imgUrl = null
        vm.fileRecords = []
        vm.fileRecordsForUpload = []
      })
      .catch((err) => {
        dispatch('auth/setRequestError', { err }, { root: true })
      })
  }
}

export default {
  state,
  getters,
  mutations,
  actions
}
