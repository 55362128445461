export const defaultMenuType = 'menu-default' // 'menu-default', 'menu-sub-hidden', 'menu-hidden';

export const subHiddenBreakpoint = 1440
export const menuHiddenBreakpoint = 768

export const defaultLocale = 'en'

export const localeOptions = [
  { id: 'en', name: 'English' },
  { id: 'ru', name: 'Русский' },
  { id: 'uz', name: 'Uzbek' }
]

export const apiUrl = 'http://dorsaapi.sosgroup.uz/api'

export const baseUrl = 'http://dorsaapi.sosgroup.uz'
