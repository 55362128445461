import ourClients from './ourClients'
import advantages from './advantages'
import aboutUsMenu from './aboutUsMenu'
import homePage from './homePage'

export default {
  modules: {
    ourClients,
    advantages,
    aboutUsMenu,
    homePage
  }
}
