import Vue from 'vue'
import Notifications from '@/components/Common/Notification'
import Breadcrumb from '@/components/Common/Breadcrumb'
import RefreshButton from '@/components/Common/RefreshButton'
import Colxx from '@/components/Common/Colxx'

Vue.use(Notifications)
Vue.component('piaf-breadcrumb', Breadcrumb)
Vue.component('b-refresh-button', RefreshButton)
Vue.component('b-colxx', Colxx)
