import axiosApiInstance from '@/api/axios'

const urlBase = '/api/blog'

const state = {
  blogLoading: false
}

const getters = {
  blogLoading: (state) => state.blogLoading
}

const mutations = {
  setBlogLoading(state, payload) {
    state.blogLoading = payload
  }
}

const actions = {
  fetchBlogs({ dispatch }, { vm }) {
    vm.blogsLoading = true
    axiosApiInstance
      .get(vm.apiUrl)
      .then((res) => {
        vm.blogs = res.data.data
        vm.page = res.data.current_page
        vm.numberOfPages = parseInt(res.data.last_page)
        vm.perPage = res.data.per_page
      })
      .catch((err) => {
        dispatch('auth/setRequestError', { err }, { root: true })
      })
      .finally(() => {
        vm.blogsLoading = false
      })
  },

  createBlog({ commit, dispatch }, { vm, payload }) {
    const data = new FormData()

    for (const key in payload) {
      data.append(key, payload[key])
    }

    commit('setBlogLoading', false)
    axiosApiInstance
      .post(`${urlBase}/create`, data)
      .then(() => {
        vm.$router.push('/blog')
      })
      .catch((err) => {
        dispatch('auth/setRequestError', { err }, { root: true })
      })
      .finally(() => {
        commit('setBlogLoading', false)
      })
  },

  // get blog for edit
  fetchSingleBlog({ dispatch }, { vm, id }) {
    vm.blogEditLoading = true
    axiosApiInstance
      .get(`${urlBase}/edit`, {
        params: {
          id
        }
      })
      .then((res) => {
        vm.imgUrl = res.data.image
        vm.blogEditForm.title_uz = res.data.title_uz
        vm.blogEditForm.title_ru = res.data.title_ru
        vm.blogEditForm.title_en = res.data.title_en
        vm.blogEditForm.content_ru = res.data.content_ru
        vm.blogEditForm.content_en = res.data.content_en
        vm.blogEditForm.content_uz = res.data.content_uz
      })
      .catch((err) => {
        dispatch('auth/setRequestError', { err }, { root: true })
      })
      .finally(() => {
        vm.blogEditLoading = false
      })
  },

  // update blog
  updateSingleBlog({ dispatch }, { vm, payload, id }) {
    const data = new FormData()

    for (const key in payload) {
      data.append(key, payload[key])
    }

    data.append('id', id)

    axiosApiInstance
      .post(`${urlBase}/update`, data)
      .then(() => {
        vm.$bvModal.hide(`editBlogsModal${vm.data.id}`)
        vm.$emit('update')

        vm.blogEditForm.title_uz = ''
        vm.blogEditForm.title_ru = ''
        vm.blogEditForm.title_en = ''

        vm.blogEditForm.content_uz = ''
        vm.blogEditForm.content_ru = ''
        vm.blogEditForm.content_en = ''
        vm.imgUploded = false
        vm.imgUrl = null
        vm.fileRecords = []
        vm.fileRecordsForUpload = []
      })
      .catch((err) => {
        dispatch('auth/setRequestError', { err }, { root: true })
      })
  }
}

export default {
  state,
  getters,
  mutations,
  actions
}
