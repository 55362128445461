import Vue from 'vue'
import Vuex from 'vuex'

import app from '../main'
import menu from './modules/menu'
import settings from './modules/settings'
import auth from './modules/auth'
import products from './modules/products'
import categories from './modules/categories'
import media from './modules/media'
import blog from './modules/blog'
import partners from './modules/partners'
import works from './modules/works'
import calculator from './modules/calculator'
import slider from './modules/slider'
import pages from './modules/pages'
import orders from './modules/orders'
import users from './modules/users'
import fileManager from './modules/fileManager'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {},
  mutations: {
    changeLang(state, payload) {
      app.$i18n.locale = payload
      localStorage.setItem('currentLanguage', payload)
    }
  },
  actions: {
    setLang({ commit }, payload) {
      commit('changeLang', payload)
    }
  },
  modules: {
    menu,
    settings,
    auth,
    products,
    categories,
    media,
    blog,
    partners,
    works,
    calculator,
    slider,
    pages,
    orders,
    users,
    fileManager
  }
})
