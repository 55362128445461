/* eslint-disable no-unused-vars */
import axiosApiInstance from '@/api/axios'

const urlBase = '/api/canvas_type'

const state = () => ({
  calcCanvasItems: [],
  calcCanvasPageLoading: false
})

const getters = {
  calcCanvasItems: (state) => state.calcCanvasItems,
  calcCanvasPageLoading: (state) => state.calcCanvasPageLoading
}

const mutations = {
  SET_CALC_CANVAS_ITEMS(state, payload) {
    state.calcCanvasItems = payload
  },
  SET_CALC_CANVAS_LOADING(state, payload) {
    state.calcCanvasPageLoading = payload
  }
}

const actions = {
  fechCanvasTypes({ commit, dispatch }) {
    axiosApiInstance
      .get(`${urlBase}/list`)
      .then((res) => {
        commit('SET_CALC_CANVAS_ITEMS', res.data)
      })
      .catch((err) => {
        dispatch('auth/setRequestError', { err }, { root: true })
      })
  },
  createCanvasType({ commit, dispatch }, { vm, payload }) {
    const data = new FormData()

    for (const key in payload) {
      data.append(key, payload[key])
    }

    commit('SET_CALC_CANVAS_LOADING', true)
    axiosApiInstance
      .post(`${urlBase}/create`, data)
      .then(() => {
        vm.$bvModal.hide('calcCanvasTypeCreateModal')
        vm.clearForm()
        vm.$emit('update')
      })
      .catch((err) => {
        dispatch('auth/setRequestError', { err }, { root: true })
      })
      .finally(() => {
        commit('SET_CALC_CANVAS_LOADING', false)
      })
  },
  deleteCanvasType({ commit, dispatch }, { vm, id }) {
    const data = new FormData()

    data.append('id', id)

    axiosApiInstance
      .post(`${urlBase}/delete`, data)
      .then((res) => {
        if (res.status === 200) {
          vm.update()
        }
      })
      .catch((err) => {
        dispatch('auth/setRequestError', { err }, { root: true })
      })
  },
  fetchSingleCanvasType({ commit, dispatch }, { vm, id }) {
    commit('SET_CALC_CANVAS_LOADING', true)
    axiosApiInstance
      .get(`${urlBase}/edit`, {
        params: {
          id
        }
      })
      .then((res) => {
        vm.templateUrl = res.data.template
        vm.layerUrl = res.data.layer
        vm.calcCanvasTypeEditForm.name_uz = res.data.name_uz
        vm.calcCanvasTypeEditForm.name_ru = res.data.name_ru
        vm.calcCanvasTypeEditForm.name_en = res.data.name_en
        vm.calcCanvasTypeEditForm.price = res.data.price
      })
      .catch((err) => {
        dispatch('auth/setRequestError', { err }, { root: true })
      })
      .finally(() => {
        commit('SET_CALC_CANVAS_LOADING', false)
      })
  },
  updateSingleCanvasType({ commit, dispatch }, { vm, payload, id }) {
    const data = new FormData()
    for (const key in payload) {
      data.append(key, payload[key])
    }
    data.append('id', id)

    commit('SET_CALC_CANVAS_LOADING', true)
    axiosApiInstance
      .post(`${urlBase}/update`, data)
      .then(() => {
        vm.clearForm()
        vm.$bvModal.hide(`calcCanvasTypeUpdateModal${vm.uniqueId}`)
        vm.$emit('update')
      })
      .catch((err) => {
        dispatch('auth/setRequestError', { err }, { root: true })
      })
      .finally(() => {
        commit('SET_CALC_CANVAS_LOADING', false)
      })
  }
}

export default {
  state,
  getters,
  mutations,
  actions
}
