import axiosApiInstance, { axiosBaseUrl } from '@/api/axios'

import common from './common'

const state = {
  categories: [],
  categoriesModuleLoading: false
}

const getters = {
  categories: (state) => state.categories,
  categoriesModuleLoading: (state) => state.categoriesModuleLoading
}

const mutations = {
  setCategories(state, payload) {
    state.categories = payload
  },
  setCategoriesModuleLoading(state, payload) {
    state.categoriesModuleLoading = payload
  }
}

const actions = {
  fetchCategories({ commit, dispatch }) {
    commit('setCategoriesModuleLoading', true)
    commit('setCategories', [])
    axiosApiInstance
      .get('/api/category/list')
      .then((res) => {
        commit(
          'setCategories',
          res.data.sort((a, b) => parseInt(b.id) - parseInt(a.id))
        )
      })
      .catch((err) => {
        dispatch('auth/setRequestError', { err }, { root: true })
      })
      .finally(() => commit('setCategoriesModuleLoading', false))
  },

  // create category
  createCategory({ dispatch }, { data, vm }) {
    axiosBaseUrl
      .post('/api/category/create', {
        data,
        token: localStorage.getItem('token')
      })
      .then((res) => {
        if (res.status === 200) {
          vm.$refs['createNewCategory'].hide()
          vm.addCategoryForm = {}
          vm.$emit('update')
        }
      })
      .catch((err) => {
        dispatch('auth/setRequestError', { err }, { root: true })
      })
  },

  // get category for edit
  getCurrentCategory({ dispatch }, { vm }) {
    axiosBaseUrl
      .get('/api/category/edit', {
        params: {
          id: vm.id,
          token: localStorage.getItem('token')
        }
      })
      .then((res) => {
        if (res.status === 200) {
          vm.editCategoryForm.id = res.data.id
          vm.editCategoryForm.name_uz = res.data.name_uz
          vm.editCategoryForm.name_ru = res.data.name_ru
          vm.editCategoryForm.name_en = res.data.name_en
          vm.editCategoryForm.type_id = res.data.type_id
        }
      })
      .catch((err) => {
        dispatch('auth/setRequestError', { err }, { root: true })
      })
  },

  // edit category
  editCategory({ dispatch }, { vm, data }) {
    axiosBaseUrl
      .put('/api/category/update', {
        id: vm.id,
        data,
        token: localStorage.getItem('token')
      })
      .then((res) => {
        if (res.status === 200) {
          vm.$refs['modalEditRight'].hide()
          vm.$emit('update')
        }
      })
      .catch((err) => {
        dispatch('auth/setRequestError', { err }, { root: true })
      })
  },

  // create category type
  createCategoryType({ dispatch }, { payload, vm }) {
    axiosBaseUrl
      .post('/api/category_types/create', {
        data: payload,
        token: localStorage.getItem('token')
      })
      .then((res) => {
        if (res.status === 200) {
          vm.$refs['modalNestedInline'].hide()
          vm.$emit('update')
        }
      })
      .catch((err) => {
        dispatch('auth/setRequestError', { err }, { root: true })
      })
  },

  // get category type for edit
  fetchCurrentCategoryType({ dispatch }, { vm }) {
    axiosBaseUrl
      .get('/api/category_types/edit', {
        params: {
          id: vm.id,
          token: localStorage.getItem('token')
        }
      })
      .then((res) => {
        if (res.status === 200) {
          vm.editCategoryTypeForm.name_uz = res.data.name_uz
          vm.editCategoryTypeForm.name_ru = res.data.name_ru
          vm.editCategoryTypeForm.name_en = res.data.name_en
        }
      })
      .catch((err) => {
        dispatch('auth/setRequestError', { err }, { root: true })
      })
  },

  // edit category type
  editCategoryType({ dispatch }, { data, vm }) {
    axiosBaseUrl
      .put('/api/category_types/update', {
        id: vm.id,
        data,
        token: localStorage.getItem('token')
      })
      .then((res) => {
        if (res.status === 200) {
          vm.$refs['updateModalNested'].hide()
          vm.$emit('update')
        }
      })
      .catch((err) => {
        dispatch('auth/setRequestError', { err }, { root: true })
      })
  }
}

export default {
  state,
  getters,
  mutations,
  actions,
  modules: {
    common
  }
}
