import Vue from 'vue'
import App from './App'
import router from '@/router'
import store from '@/store'
import i18n from '@/plugins/vue-i18n'

/* plugins */
import '@/plugins/vue-styles'
import '@/plugins/vee-validate'
import '@/plugins/vue-frag'
import '@/plugins/vue-file-agent'
import '@/plugins/vue-custom-components'
import '@/plugins/vue-select'
import '@/plugins/vue-quill-editor'

// BootstrapVue add
import BootstrapVue from 'bootstrap-vue'
Vue.use(BootstrapVue)

// Perfect Scrollbar Add
import vuePerfectScrollbar from 'vue-perfect-scrollbar'
Vue.component('vue-perfect-scrollbar', vuePerfectScrollbar)

// Currency input
import VueCurrencyInput from 'vue-currency-input'
Vue.use(VueCurrencyInput, {
  globalOptions: {
    locale: 'en',
    currency: {
      currency: 'UZS',
      suffix: ' UZS'
    },
    precision: 2,
    valueRange: { min: 0 },
    allowNegative: false
  }
})

// Calendar
import VCalendar from 'v-calendar'
Vue.use(VCalendar, {
  firstDayOfWeek: 2,
  formats: {
    title: 'MMMM YYYY',
    weekdays: 'W',
    navMonths: 'MMM',
    input: ['L', 'YYYY-MM-DD', 'YYYY/MM/DD'],
    dayPopover: 'L'
  },
  datePickerShowDayPopover: false,
  popoverExpanded: true,
  popoverDirection: 'bottom'
})

import VueConfirmDialog from 'vue-confirm-dialog'
Vue.use(VueConfirmDialog)

Vue.config.productionTip = false

export default new Vue({
  i18n,
  router,
  store,
  render: (h) => h(App)
}).$mount('#app')
