import axiosApiInstance from '@/api/axios'
const urlBase = '/api/calc_details'

const state = () => ({
  requirements: [],
  multipleRequirements: [],
  isLoading: false
})

const getters = {
  requirements: (state) => state.requirements,
  multipleRequirements: (state) => state.multipleRequirements,
  isLoading: (state) => state.isLoading
}

const mutations = {
  SET_REQUIREMENTS(state, payload) {
    state.requirements = payload
  },
  SET_MULTIPLE_REQUIREMENTS(state, payload) {
    state.multipleRequirements = payload
  },
  SET_LOADING(state, payload) {
    state.isLoading = payload
  }
}

const actions = {
  async fetchRequirements({ commit, dispatch }, { categoryId, canvasId }) {
    commit('SET_LOADING', true)
    try {
      const res = await axiosApiInstance.get(`${urlBase}/requirements`, {
        params: {
          calc_cat_id: categoryId,
          canvas_id: canvasId
        }
      })
      commit('SET_REQUIREMENTS', res.data)
    } catch (err) {
      dispatch('auth/setRequestError', { err }, { root: true })
    } finally {
      commit('SET_LOADING', false)
    }
  },
  async fetchMultipleRequirements(
    { commit, dispatch },
    { categoryId, canvasId }
  ) {
    commit('SET_LOADING', true)
    let requirements = []
    try {
      for (const singleCategoryId of categoryId) {
        const res = await axiosApiInstance.get(`${urlBase}/requirements`, {
          params: {
            calc_cat_id: singleCategoryId,
            canvas_id: canvasId
          }
        })
        requirements.push({ id: singleCategoryId, data: res.data })
      }
      commit('SET_MULTIPLE_REQUIREMENTS', requirements)
    } catch (err) {
      dispatch('auth/setRequestError', { err }, { root: true })
    } finally {
      commit('SET_LOADING', false)
    }
  }
}

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
}
