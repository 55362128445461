import axiosApiInstance from '@/api/axios'

const urlBase = '/api/advantages'

const state = {
  advantagesLoading: false
}

const getters = {
  advantagesLoading: (state) => state.advantagesLoading
}

const mutations = {
  SET_ADVANTAGES_LOADING(state, payload) {
    state.advantagesLoading = payload
  }
}

const actions = {
  fetchAdvantages({ dispatch }, { vm }) {
    vm.advantagesLoading = true
    axiosApiInstance
      .get(vm.apiUrl)
      .then((res) => {
        vm.advantages = res.data
      })
      .catch((err) => {
        dispatch('auth/setRequestError', { err }, { root: true })
      })
      .finally(() => {
        vm.advantagesLoading = false
      })
  },

  createAdvantage({ commit, dispatch }, { vm, payload }) {
    const data = new FormData()

    for (const key in payload) {
      data.append(key, payload[key])
    }

    commit('SET_ADVANTAGES_LOADING', true)
    axiosApiInstance
      .post(`${urlBase}/create`, data)
      .then(() => {
        vm.$router.push('/pages/advantages')
      })
      .catch((err) => {
        dispatch('auth/setRequestError', { err }, { root: true })
      })
      .finally(() => {
        commit('SET_ADVANTAGES_LOADING', false)
      })
  },

  fetchSingleAdvantage({ dispatch }, { vm, id }) {
    vm.clientsPageLoading = true
    axiosApiInstance
      .get(`${urlBase}/edit`, {
        params: {
          id
        }
      })
      .then((res) => {
        vm.advantageEditForm.name_uz = res.data.name_uz
        vm.advantageEditForm.name_ru = res.data.name_ru
        vm.advantageEditForm.name_en = res.data.name_en
        vm.advantageEditForm.data = res.data.data
        vm.advantageEditForm.sort = res.data.sort
      })
      .catch((err) => {
        dispatch('auth/setRequestError', { err }, { root: true })
      })
      .finally(() => {
        vm.clientsPageLoading = false
      })
  },

  // update client
  updateSingleAdvantage({ dispatch }, { vm, payload, id }) {
    const data = new FormData()

    for (const key in payload) {
      data.append(key, payload[key])
    }

    data.append('id', id)

    axiosApiInstance
      .post(`${urlBase}/update`, data)
      .then(() => {
        vm.$bvModal.hide(`editAdvantageModal${vm.data.id}`)
        vm.$emit('update')

        vm.clientsPageLoading = false
        vm.advantageEditForm.name_uz = ''
        vm.advantageEditForm.name_ru = ''
        vm.advantageEditForm.name_en = ''
        vm.advantageEditForm.data = ''
        vm.advantageEditForm.sort = ''
      })
      .catch((err) => {
        dispatch('auth/setRequestError', { err }, { root: true })
      })
  }
}

export default {
  state,
  getters,
  mutations,
  actions
}
