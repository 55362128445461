import axiosApiInstance from '@/api/axios'

const state = {
  mainSettings: {}
}

const getters = {
  mainSettings: (state) => state.mainSettings
}

const mutations = {
  SET_SETTINGS(state, payload) {
    state.mainSettings = payload
  }
}

const actions = {
  fetchSettings({ commit }) {
    axiosApiInstance
      .get('/api/settings/get')
      .then((res) => {
        commit('SET_SETTINGS', res.data)
      })
      .catch((err) => console.error(err))
  },
  fetchSettingsForEdit({ dispatch }, { vm }) {
    axiosApiInstance
      .get('/api/settings/get')
      .then((res) => {
        const data = res.data

        vm.settingsUpdateForm.address_uz = data.address_uz
        vm.settingsUpdateForm.address_ru = data.address_ru
        vm.settingsUpdateForm.address_en = data.address_en

        vm.settingsUpdateForm.address_2_uz = data.address_2_uz
        vm.settingsUpdateForm.address_2_ru = data.address_2_ru
        vm.settingsUpdateForm.address_2_en = data.address_2_en

        vm.settingsUpdateForm.email = data.email
        vm.settingsUpdateForm.email_2 = data.email_2

        vm.settingsUpdateForm.phone = data.phone
        vm.settingsUpdateForm.phone_2 = data.phone_2

        vm.settingsUpdateForm.telegram = data.telegram
        vm.settingsUpdateForm.twitter = data.twitter
        vm.settingsUpdateForm.instagram = data.instagram
        vm.settingsUpdateForm.facebook = data.facebook

        vm.settingsUpdateForm.lat = data.lat
        vm.settingsUpdateForm.long = data.long

        vm.settingsUpdateForm.lat_2 = data.lat_2
        vm.settingsUpdateForm.long_2 = data.long_2
      })
      .catch((err) => {
        dispatch('auth/setRequestError', { err }, { root: true })
      })
  },
  updateSettings({ dispatch }, { payload, vm }) {
    const data = new FormData()
    for (const key in payload) {
      data.append(key, payload[key])
    }
    axiosApiInstance
      .post('/api/settings/update', data)
      .then(() => {
        vm.$bvModal.hide('updateSettingsModal')
        vm.$emit('update')
        vm.cleanForm()
      })
      .catch((err) => {
        dispatch('auth/setRequestError', { err }, { root: true })
      })
  }
}

export default {
  state,
  getters,
  mutations,
  actions
}
