import axiosApiInstance from '@/api/axios'
import calc_details from './calc_details'
import calc_canvas from './calc_canvas'
import calculatorHelpers from './helpers'

const state = {
  calcCategories: [],
  calcCategoriesLoading: false
}

const getters = {
  calcCategories: (state) => state.calcCategories,
  calcCategoriesLoading: (state) => state.calcCategoriesLoading
}

const mutations = {
  SET_CALC_CATEGORIES(state, payload) {
    state.calcCategories = payload
  },
  SET_CALC_CATEGORIES_LOADING(state, payload) {
    state.calcCategoriesLoading = payload
  }
}

const actions = {
  fetchCalcCategories({ commit, dispatch }) {
    commit('SET_CALC_CATEGORIES_LOADING', true)
    axiosApiInstance
      .get('/api/calculator_categories/list')
      .then((res) => {
        const data = res.data.sort((a, b) => a.sort - b.sort)
        commit('SET_CALC_CATEGORIES', data)
      })
      .catch((err) => {
        dispatch('auth/setRequestError', { err }, { root: true })
      })
      .finally(() => {
        commit('SET_CALC_CATEGORIES_LOADING', false)
      })
  }
}

export default {
  state,
  getters,
  mutations,
  actions,
  modules: {
    calc_details,
    calc_canvas,
    calculatorHelpers
  }
}
