import axiosApiInstance from '@/api/axios'
const urlBase = '/api/partner'

const state = {
  partnersLoading: false
}

const getters = {
  partnersLoading: (state) => state.partnersLoading
}

const mutations = {
  setPartnersLoading(state, payload) {
    state.partnersLoading = payload
  }
}

const actions = {
  fetchPartners({ dispatch }, { vm }) {
    vm.partnersLoading = true
    axiosApiInstance
      .get(vm.apiUrl)
      .then((res) => {
        vm.partners = res.data.data
        vm.page = res.data.current_page
        vm.numberOfPages = parseInt(res.data.last_page)
        vm.perPage = res.data.per_page
      })
      .catch((err) => {
        dispatch('auth/setRequestError', { err }, { root: true })
      })
      .finally(() => {
        vm.partnersLoading = false
      })
  },

  createPartner({ commit, dispatch }, { vm, payload }) {
    const data = new FormData()

    for (const key in payload) {
      data.append(key, payload[key])
    }

    commit('setPartnersLoading', true)
    axiosApiInstance
      .post(`${urlBase}/create`, data)
      .then(() => {
        vm.$router.push('/partners')
      })
      .catch((err) => {
        dispatch('auth/setRequestError', { err }, { root: true })
      })
      .finally(() => {
        commit('setPartnersLoading', false)
      })
  },

  fetchSinglePartner({ dispatch }, { vm, id }) {
    vm.partnersLoading = true
    axiosApiInstance
      .get(`${urlBase}/edit`, {
        params: {
          id
        }
      })
      .then((res) => {
        vm.imgUrl = res.data.logo
        vm.partnerEditForm.description_uz = res.data.description_uz
        vm.partnerEditForm.description_ru = res.data.description_ru
        vm.partnerEditForm.description_en = res.data.description_en
        vm.partnerEditForm.name = res.data.name
      })
      .catch((err) => {
        dispatch('auth/setRequestError', { err }, { root: true })
      })
      .finally(() => {
        vm.partnersLoading = false
      })
  },

  // update blog
  updateSinglePartner({ dispatch }, { vm, payload, id }) {
    const data = new FormData()
    for (const key in payload) {
      data.append(key, payload[key])
    }
    data.append('id', id)

    axiosApiInstance
      .post(`${urlBase}/update`, data)
      .then(() => {
        vm.$bvModal.hide(`editPartnersModal${vm.data.id}`)
        vm.$emit('update')

        vm.partnerEditForm.description_uz = ''
        vm.partnerEditForm.description_ru = ''
        vm.partnerEditForm.description_en = ''

        vm.partnerEditForm.name = ''
        vm.imgUploded = false
        vm.imgUrl = null
        vm.fileRecords = []
        vm.fileRecordsForUpload = []
      })
      .catch((err) => {
        dispatch('auth/setRequestError', { err }, { root: true })
      })
  }
}

export default {
  state,
  getters,
  mutations,
  actions
}
