/* eslint-disable no-unused-vars */
import Vue from 'vue'
import VueQuillEditor from 'vue-quill-editor'
Vue.use(VueQuillEditor, {
  modules: {
    toolbar: [
      [
        'bold',
        'italic',
        'color',
        'underline',
        'strike',
        'blockquote',
        'image',
        'video',
        'formula'
      ],
      [
        { list: 'ordered' },
        { list: 'bullet' },
        { indent: '-1' },
        { indent: '+1' }
      ],
      ['link'],
      ['clean']
    ]
  }
})
import 'quill/dist/quill.core.css'
import 'quill/dist/quill.snow.css'
import 'quill/dist/quill.bubble.css'

function contentWatchOverride(newVal, _oldVal) {
  if (this.quill) {
    if (newVal && newVal !== this._content) {
      this._content = newVal
      const delta = this.quill.clipboard.convert(newVal)
      this.quill.setContents(delta)
    } else if (!newVal) {
      this.quill.setText('')
    }
  }
}

const Base = Vue.options.components['quill-editor']
const CustomQuillEditor = Base.extend({
  beforeCreate() {
    Vue.set(this.$options.watch, 'content', contentWatchOverride.bind(this))
    Vue.set(this.$options.watch, 'value', contentWatchOverride.bind(this))
  }
})

Vue.component('quill-editor', CustomQuillEditor)
