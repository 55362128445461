export const addCommas = (nStr) => {
  nStr += ''
  const x = nStr.split('.')
  let x1 = x[0]
  const x2 = x.length > 1 ? '.' + x[1] : ''
  const rgx = /(\d+)(\d{3})/
  while (rgx.test(x1)) {
    x1 = x1.replace(rgx, '$1' + ',' + '$2')
  }
  return x1 + x2
}

export const isObject = (val) => {
  return val != null && typeof val === 'object' && Array.isArray(val) === false
}

export const parseJson = (str) => {
  try {
    return JSON.parse(str)
  } catch (e) {
    return false
  }
}

export const mapOrder = (array, order, key) => {
  array.sort(function (a, b) {
    const A = a[key]
    const B = b[key]
    if (order.indexOf(A + '') > order.indexOf(B + '')) {
      return 1
    } else {
      return -1
    }
  })
  return array
}

export const formatDateWithTime = (date) => {
  let selectedDate
  if (typeof date === 'string') {
    selectedDate = date.length ? new Date(date) : new Date()
  } else {
    selectedDate = date ? new Date(date) : new Date()
  }

  let dd = selectedDate.getDate()
  let MM = selectedDate.getMonth() + 1
  const yyyy = selectedDate.getFullYear()

  let hh = selectedDate.getHours()
  let mm = selectedDate.getMinutes()

  if (dd < 10) {
    dd = `0${dd}`
  }
  if (MM < 10) {
    MM = `0${MM}`
  }
  if (hh < 10) {
    hh = `0${hh}`
  }
  if (mm < 10) {
    mm = `0${mm}`
  }

  return `${dd}.${MM}.${yyyy} ${hh}:${mm}`
}

export const getDateWithFormat = (date) => {
  const selectedDate = new Date(date)
  let dd = selectedDate.getDate()
  let mm = selectedDate.getMonth() + 1 // January is 0!

  const yyyy = selectedDate.getFullYear()
  if (dd < 10) {
    dd = '0' + dd
  }
  if (mm < 10) {
    mm = '0' + mm
  }
  return yyyy + '-' + mm + '-' + dd
}

export const getCurrentTime = () => {
  const now = new Date()
  return now.getHours() + ':' + now.getMinutes()
}

export const findObjectInArray = (arr = [], param, query) => {
  if ((Array.isArray(arr) && arr.length === 0) || !param || !query) return {}
  const obj = arr.find((el) => el[param] === query)
  return (isObject(obj) && obj) || {}
}

export const ObjectFilterOut = (object, filterKeys) => {
  const langFilterKeys = []
  for (const key of filterKeys) {
    langFilterKeys.push(key.concat('_', 'en'))
    langFilterKeys.push(key.concat('_', 'ru'))
    langFilterKeys.push(key.concat('_', 'uz'))
  }
  const asArray = Object.entries(object)
  const filtered = asArray.filter(
    // eslint-disable-next-line no-unused-vars
    ([key, _value]) => !langFilterKeys.includes(key)
  )
  return Object.fromEntries(filtered)
}

export const handleArrayDataInternalization = (data, langKeys, currentLang) => {
  switch (currentLang) {
    case 'en':
      return data.map((i) => {
        const langData = {}

        for (const key of langKeys) {
          const langKey = key.concat('_', 'en')
          if (i[langKey]) {
            langData[key] = i[langKey]
          }
        }

        return {
          ...ObjectFilterOut(i, langKeys),
          ...langData
        }
      })

    case 'ru':
      return data.map((i) => {
        const langData = {}

        for (const key of langKeys) {
          const langKey = key.concat('_', 'ru')
          if (i[langKey]) {
            langData[key] = i[langKey]
          }
        }

        return {
          ...ObjectFilterOut(i, langKeys),
          ...langData
        }
      })

    case 'uz':
      return data.map((i) => {
        const langData = {}

        for (const key of langKeys) {
          const langKey = key.concat('_', 'uz')
          if (i[langKey]) {
            langData[key] = i[langKey]
          }
        }

        return {
          ...ObjectFilterOut(i, langKeys),
          ...langData
        }
      })

    default:
      return []
  }
}

export const handleObjectInternalization = (data, langKeys, currentLang) => {
  const langData = {}
  switch (currentLang) {
    case 'en':
      for (const key of langKeys) {
        const langKey = key.concat('_', 'en')
        if (data[langKey]) {
          langData[key] = data[langKey]
        }
      }

      return {
        ...ObjectFilterOut(data, langKeys),
        ...langData
      }

    case 'ru':
      for (const key of langKeys) {
        const langKey = key.concat('_', 'ru')
        if (data[langKey]) {
          langData[key] = data[langKey]
        }
      }

      return {
        ...ObjectFilterOut(data, langKeys),
        ...langData
      }

    case 'uz':
      for (const key of langKeys) {
        const langKey = key.concat('_', 'uz')
        if (data[langKey]) {
          langData[key] = data[langKey]
        }
      }

      return {
        ...ObjectFilterOut(data, langKeys),
        ...langData
      }

    default:
      return []
  }
}

export const formatPrice = (number, n, x, s, c) => {
  /**
   * Number.prototype.format(n, x, s, c)
   *
   * @param number number: number to format
   * @param integer     n: length of decimal
   * @param integer     x: length of whole part
   * @param mixed       s: sections delimiter
   * @param mixed       c: decimal delimiter
   */
  const re = '\\d(?=(\\d{' + (x || 3) + '})+' + (n > 0 ? '\\D' : '$') + ')'
  const num = number.toFixed(Math.max(0, ~~n))

  return (c ? num.replace('.', c) : num).replace(
    new RegExp(re, 'g'),
    '$&' + (s || ',')
  )
}
