import axiosApiInstance from '@/api/axios'
const urlBase = '/api/homepage'

const state = {
  homePageSectionsDetails: {}
}

const getters = {
  homePageSectionsDetails: (state) => state.homePageSectionsDetails
}

const mutations = {
  SET_HOMEPAGE_SECTIONS_DATA(state, payload) {
    state.homePageSectionsDetails = payload
  }
}

const actions = {
  fetchHomePageSectionsDetails({ commit, dispatch }) {
    axiosApiInstance
      .get(`${urlBase}/get_sections`)
      .then((res) => {
        commit('SET_HOMEPAGE_SECTIONS_DATA', res.data)
      })
      .catch((err) => {
        dispatch('auth/setRequestError', { err }, { root: true })
      })
  },

  fetchHomePageSectionsDetailsForEdit({ dispatch }, { vm }) {
    vm.homePageSectionsLoading = true
    axiosApiInstance
      .get(`${urlBase}/get_sections`)
      .then((res) => {
        const data = res.data

        vm.uniqueId = data.id
        vm.aboutImgUrl = data.about_image
        vm.calculatorImgUrl = data.calc_image

        vm.homePageSectionEditForm.about_description_en =
          data.about_description_en
        vm.homePageSectionEditForm.about_description_ru =
          data.about_description_ru
        vm.homePageSectionEditForm.about_description_uz =
          data.about_description_uz

        vm.homePageSectionEditForm.about_title_en = data.about_title_en
        vm.homePageSectionEditForm.about_title_ru = data.about_title_ru
        vm.homePageSectionEditForm.about_title_uz = data.about_title_uz

        vm.homePageSectionEditForm.calc_description_en =
          data.calc_description_en
        vm.homePageSectionEditForm.calc_description_ru =
          data.calc_description_ru
        vm.homePageSectionEditForm.calc_description_uz =
          data.calc_description_uz

        vm.homePageSectionEditForm.calc_title_en = data.calc_title_en
        vm.homePageSectionEditForm.calc_title_ru = data.calc_title_ru
        vm.homePageSectionEditForm.calc_title_uz = data.calc_title_uz
      })
      .catch((err) => {
        dispatch('auth/setRequestError', { err }, { root: true })
      })
      .finally(() => {
        vm.homePageSectionsLoading = false
      })
  },

  updateHomePageSectionsDetails({ dispatch }, { vm, payload, id }) {
    const data = new FormData()

    for (const key in payload) {
      data.append(key, payload[key])
    }

    data.append('id', id)

    vm.homePageSectionsLoading = true
    axiosApiInstance
      .post(`${urlBase}/update_sections`, data)
      .then(() => {
        vm.$emit('update')
        vm.$bvModal.hide('updateHomePageSectionsDetailsModal')

        vm.aboutImgUrl = null
        vm.calculatorImgUrl = null
        vm.aboutImgUploaded = false
        vm.calculatorImgUploaded = false

        vm.fileRecordsForAbout = []
        vm.fileRecordsForUploadForAbout = []
        vm.fileRecordsForCalculator = []
        vm.fileRecordsForUploadForCalculator = []
        vm.uniqueId = ''

        vm.homePageSectionEditForm.about_description_en = ''
        vm.homePageSectionEditForm.about_description_ru = ''
        vm.homePageSectionEditForm.about_description_uz = ''

        vm.homePageSectionEditForm.about_title_en = ''
        vm.homePageSectionEditForm.about_title_ru = ''
        vm.homePageSectionEditForm.about_title_uz = ''

        vm.homePageSectionEditForm.calc_description_en = ''
        vm.homePageSectionEditForm.calc_description_ru = ''
        vm.homePageSectionEditForm.calc_description_uz = ''

        vm.homePageSectionEditForm.calc_title_en = ''
        vm.homePageSectionEditForm.calc_title_ru = ''
        vm.homePageSectionEditForm.calc_title_uz = ''
      })
      .catch((err) => {
        dispatch('auth/setRequestError', { err }, { root: true })
      })
      .finally(() => {
        vm.homePageSectionsLoading = false
      })
  }
}

export default {
  state,
  getters,
  mutations,
  actions
}
