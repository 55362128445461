import Vue from 'vue'
import VueI18n from 'vue-i18n'
import { extend } from 'vee-validate'
import { required, min, max, length, email } from 'vee-validate/dist/rules'
import { defaultLocale, localeOptions } from '@/constants/config'

import en from '@/locales/en.json'
import ru from '@/locales/ru.json'
import uz from '@/locales/uz.json'

Vue.use(VueI18n)

const messages = { en, ru, uz }
const locale =
  localStorage.getItem('currentLanguage') &&
  localeOptions.filter((x) => x.id === localStorage.getItem('currentLanguage'))
    .length > 0
    ? localStorage.getItem('currentLanguage')
    : defaultLocale

const i18n = new VueI18n({
  locale: locale,
  fallbackLocale: 'ru',
  messages
})

extend('required', {
  ...required,
  message: (_, values) => i18n.t('formErrors.fieldRequired', values)
})

extend('min', {
  ...min,
  message: (field, values) =>
    i18n.t(`formErrors.${field}Min${values.length}Char`, values)
})

extend('max', {
  ...max,
  message: (field, values) =>
    i18n.t(`formErrors.${field}Max${values.length}Char`, values)
})

extend('length', {
  ...length,
  message: (field, values) =>
    i18n.t(`formErrors.${field}${values.length}Char`, values)
})

extend('email', {
  ...email,
  message: (_, values) => i18n.t('formErrors.mustBeValidEmail', values)
})

export default i18n
