import axiosApiInstance from '@/api/axios'

const state = {
  sliderPageLoading: false
}

const getters = {
  sliderPageLoading: (state) => state.sliderPageLoading
}

const actions = {
  fetchSliders({ dispatch }, { vm }) {
    vm.slidersLoading = true
    axiosApiInstance
      .get(vm.apiUrl)
      .then((res) => {
        vm.sliders = res.data.data
        vm.page = res.data.current_page
        vm.numberOfPages = parseInt(res.data.last_page)
        vm.perPage = res.data.per_page
      })
      .catch((err) => {
        dispatch('auth/setRequestError', { err }, { root: true })
      })
      .finally(() => {
        vm.slidersLoading = false
      })
  },

  createSlider({ dispatch }, { vm, payload }) {
    const data = new FormData()
    for (const key in payload) {
      data.append(key, payload[key])
    }

    axiosApiInstance
      .post('/api/slider/create', data)
      .then(() => {
        vm.$router.push('/slider')
      })
      .catch((err) => {
        dispatch('auth/setRequestError', { err }, { root: true })
      })
  },

  deleteSlider({ dispatch }, { vm, payload }) {
    const data = new FormData()

    data.append('id', payload)

    axiosApiInstance
      .post('/api/slider/delete', data)
      .then(() => {
        vm.$emit('update')
      })
      .catch((err) => {
        dispatch('auth/setRequestError', { err }, { root: true })
      })
  },

  fetchSingleSlider({ dispatch }, { vm, id }) {
    vm.sliderLoading = true
    axiosApiInstance
      .get('/api/slider/edit', {
        params: {
          id
        }
      })
      .then((res) => {
        vm.imgUrl = res.data.image

        vm.sliderEditForm.description_uz = res.data.description_uz
        vm.sliderEditForm.description_ru = res.data.description_ru
        vm.sliderEditForm.description_en = res.data.description_en

        vm.sliderEditForm.title_uz = res.data.title_uz
        vm.sliderEditForm.title_ru = res.data.title_ru
        vm.sliderEditForm.title_en = res.data.title_en

        vm.sliderEditForm.button_name_uz = res.data.button_name_uz
        vm.sliderEditForm.button_name_ru = res.data.button_name_ru
        vm.sliderEditForm.button_name_en = res.data.button_name_en

        vm.sliderEditForm.button_url = res.data.button_url
        vm.sliderEditForm.active = res.data.active === 'yes' ? true : false
      })
      .catch((err) => {
        dispatch('auth/setRequestError', { err }, { root: true })
      })
      .finally(() => {
        vm.sliderLoading = false
      })
  },

  updateSingleSlider({ dispatch }, { vm, payload, id }) {
    const data = new FormData()

    for (const key in payload) {
      data.append(key, payload[key])
    }

    data.append('id', id)

    axiosApiInstance
      .post('/api/slider/update', data)
      .then(() => {
        vm.$bvModal.hide(`editSlider${id}Modal`)
        vm.$emit('update')

        vm.imgUrl = null
        vm.imgUploded = false

        vm.fileRecords = []
        vm.fileRecordsForUpload = []

        vm.sliderEditForm.description_uz = ''
        vm.sliderEditForm.description_ru = ''
        vm.sliderEditForm.description_en = ''

        vm.sliderEditForm.title_uz = ''
        vm.sliderEditForm.title_ru = ''
        vm.sliderEditForm.title_en = ''

        vm.sliderEditForm.button_name_uz = ''
        vm.sliderEditForm.button_name_ru = ''
        vm.sliderEditForm.button_name_en = ''

        vm.sliderEditForm.button_url = ''
        vm.sliderEditForm.active = false
      })
      .catch((err) => {
        dispatch('auth/setRequestError', { err }, { root: true })
      })
  }
}

export default {
  state,
  getters,
  actions
}
