import axiosApiInstance from '@/api/axios'
const urlBase = '/api/our_clients'

const state = {
  clientsPageLoading: false
}

const getters = {
  clientsPageLoading: (state) => state.clientsPageLoading
}

const mutations = {
  SET_OUR_CLIENTS_LOADING(state, payload) {
    state.clientsPageLoading = payload
  }
}

const actions = {
  fetchOurClients({ dispatch }, { vm }) {
    vm.ourClientsLoading = true
    axiosApiInstance
      .get(vm.apiUrl)
      .then((res) => {
        vm.ourClients = res.data.data
        vm.page = res.data.current_page
        vm.numberOfPages = parseInt(res.data.last_page)
        vm.perPage = res.data.per_page
      })
      .catch((err) => {
        dispatch('auth/setRequestError', { err }, { root: true })
      })
      .finally(() => {
        vm.ourClientsLoading = false
      })
  },

  createClient({ commit, dispatch }, { vm, payload }) {
    const data = new FormData()

    for (const key in payload) {
      data.append(key, payload[key])
    }

    commit('SET_OUR_CLIENTS_LOADING', true)
    axiosApiInstance
      .post(`${urlBase}/create`, data)
      .then(() => {
        vm.$router.push('/pages/our-clients')
      })
      .catch((err) => {
        dispatch('auth/setRequestError', { err }, { root: true })
      })
      .finally(() => {
        commit('SET_OUR_CLIENTS_LOADING', false)
      })
  },

  fetchSingleClient({ dispatch }, { vm, id }) {
    vm.ourClientsLoading = true
    axiosApiInstance
      .get(`${urlBase}/edit`, {
        params: {
          id
        }
      })
      .then((res) => {
        vm.imgUrl = res.data.logo
        vm.clientEditForm.name = res.data.name
        vm.clientEditForm.link = res.data.link
      })
      .catch((err) => {
        dispatch('auth/setRequestError', { err }, { root: true })
      })
      .finally(() => {
        vm.ourClientsLoading = false
      })
  },

  // update client
  updateSingleClient({ dispatch }, { vm, payload, id }) {
    const data = new FormData()

    for (const key in payload) {
      data.append(key, payload[key])
    }

    data.append('id', id)

    axiosApiInstance
      .post(`${urlBase}/update`, data)
      .then(() => {
        vm.$bvModal.hide(`editOurClientModal${vm.data.id}`)
        vm.$emit('update')

        vm.ourClientsLoading = false
        vm.clientEditForm.name = ''
        vm.clientEditForm.link = ''
        vm.imgUploded = false
        vm.imgUrl = null
        vm.fileRecords = []
        vm.fileRecordsForUpload = []
      })
      .catch((err) => {
        dispatch('auth/setRequestError', { err }, { root: true })
      })
  }
}

export default {
  state,
  getters,
  mutations,
  actions
}
