import { axiosBaseUrl } from '@/api/axios'
import router from '@/router'
import { isObject } from '@/utils'

const state = () => ({
  currentUser:
    localStorage.getItem('user') != null
      ? JSON.parse(localStorage.getItem('user'))
      : null,
  loginError: null,
  requestError: null,
  processing: false
})

const getters = {
  currentUser: (state) => state.currentUser,
  processing: (state) => state.processing,
  loginError: (state) => state.loginError
}

const mutations = {
  SET_USER(state, payload) {
    state.currentUser = payload
    state.processing = false
    state.loginError = null
  },
  SET_LOGOUT(state) {
    state.currentUser = null
    state.processing = false
    state.loginError = null
  },
  SET_PROCESSING(state, payload) {
    state.processing = payload
    state.loginError = null
  },
  SET_ERROR(state, payload) {
    state.loginError = payload
    state.currentUser = null
    state.processing = false
  },
  CLEAR_ERROR(state) {
    state.loginError = null
  },
  SET_REQUEST_ERROR(state, payload) {
    state.requestError = payload
  }
}

const actions = {
  login({ commit }, payload) {
    commit('CLEAR_ERROR')
    commit('SET_PROCESSING', true)

    const config = {
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded'
      }
    }

    const data = new FormData()

    for (const key in payload) {
      data.append(key, payload[key])
    }

    axiosBaseUrl
      .post('/api/auth/login', data, config)
      .then((res) => {
        localStorage.setItem('token', res.data.access_token)
        localStorage.setItem('user', JSON.stringify(res.data.user))

        commit('SET_USER', JSON.parse(localStorage.getItem('user')))
        router.push('/')
      })
      .catch((err) => {
        commit('SET_ERROR', err.message)
        setTimeout(() => {
          commit('CLEAR_ERROR')
        }, 3000)
      })
  },
  signOut({ commit }) {
    localStorage.removeItem('user')
    localStorage.removeItem('token')
    localStorage.clear()
    commit('SET_LOGOUT')
    router.push('/auth/login')
  },
  setRequestError({ commit, dispatch }, { err, notify }) {
    if (!isObject(err)) return

    const res = err.response
    switch (res.status) {
      case 400:
        commit('SET_REQUEST_ERROR', res.data)
        if (typeof notify === 'function')
          notify('error', 'error', 'inputCorrectData')
        break

      case 500:
        commit('SET_REQUEST_ERROR', 'Internal Server Error')
        if (typeof notify === 'function')
          notify('error', 'error', 'internalServerError')
        break

      case 401:
        commit('SET_REQUEST_ERROR', 'Unauthorized')
        dispatch('signOut')
        break

      case 403:
        commit('SET_REQUEST_ERROR', 'Forbidden')
        dispatch('signOut')
        break

      default:
        commit('SET_REQUEST_ERROR', null)
        break
    }
  }
}

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
}
