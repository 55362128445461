import axiosApiInstance from '../../../api/axios'

const state = {
  categoryTypes: []
}

const getters = {
  categoryTypes: (state) => state.categoryTypes
}

const mutations = {
  setCategoryTypes(state, payload) {
    state.categoryTypes = payload
  }
}

const actions = {
  fetchCategoryTypes({ commit, dispatch }) {
    commit('setCategoryTypes', [])
    axiosApiInstance
      .get('/api/category_types/list')
      .then((res) => {
        if (res.status === 200) {
          commit(
            'setCategoryTypes',
            res.data.sort((a, b) => parseInt(b.id) - parseInt(a.id))
          )
        }
      })
      .catch((err) => {
        commit('setCategoryTypes', [])
        dispatch('auth/setRequestError', { err }, { root: true })
      })
  }
}

export default {
  state,
  getters,
  mutations,
  actions
}
