import axios from 'axios'
import { baseUrl } from '../constants/config'

const axiosApiInstance = axios.create({
  baseURL: baseUrl
})

export const axiosBaseUrl = axios.create({
  baseURL: baseUrl
})

export const axiosTokenInParams = axios.create({
  baseURL: baseUrl,
  params: {
    token: localStorage.getItem('token')
  }
})

// Request interceptor for API calls
axiosApiInstance.interceptors.request.use(
  (config) => {
    const token = localStorage.getItem('token')

    if (token) {
      config.headers = {
        Authorization: `Bearer ${token}`,
        Accept: 'application/json',
        'Content-Type': 'application/x-www-form-urlencoded'
      }
    }
    return config
  },
  (error) => {
    Promise.reject(error)
  }
)

export default axiosApiInstance
