import axiosApiInstance from '@/api/axios'

const urlBase = '/api/videos'

export default {
  state: {},
  getters: {},
  mutations: {},
  actions: {
    createVideo({ dispatch }, { vm, payload }) {
      const data = new FormData()
      for (const key in payload) {
        data.append(key, payload[key])
      }

      axiosApiInstance
        .post(`${urlBase}/create`, data)
        .then(() => {
          vm.$bvModal.hide('createVideoModal')
          vm.$emit('update')
        })
        .catch((err) => {
          dispatch('auth/setRequestError', { err }, { root: true })
        })
    },

    deleteVideo({ dispatch }, { vm, id }) {
      const data = new FormData()
      data.append('id', id)
      axiosApiInstance
        .post(`${urlBase}/delete`, data)
        .then(() => {
          vm.$emit('update')
        })
        .catch((err) => {
          dispatch('auth/setRequestError', { err }, { root: true })
        })
    },

    // get single video for edit
    fetchSingleVideo({ dispatch }, { vm, id }) {
      axiosApiInstance
        .get(`${urlBase}/edit`, {
          params: {
            id
          }
        })
        .then((res) => {
          if (res.data.frame !== null) {
            vm.videoUploadType = 'frame'
          } else {
            vm.videoUploadType = 'fileUpload'
          }

          vm.imgUrl = res.data.poster
          vm.videoUrl = res.data.path
          vm.videoEditForm.title_en = res.data.title_en
          vm.videoEditForm.title_ru = res.data.title_ru
          vm.videoEditForm.title_uz = res.data.title_uz
          vm.frame = res.data.frame === null ? '' : res.data.frame
        })
        .catch((err) => {
          dispatch('auth/setRequestError', { err }, { root: true })
        })
    },

    editSingleVideo({ dispatch }, { vm, payload, id }) {
      const data = new FormData()

      for (const key in payload) {
        data.append(key, payload[key])
      }

      data.append('id', id)

      axiosApiInstance
        .post(`${urlBase}/update`, data)
        .then(() => {
          vm.$bvModal.hide(`editVideo${vm.uniqueId}Modal`)
          vm.$emit('update')

          vm.imgUploded = false
          vm.videoUploaded = false
          vm.imgUrl = null
          vm.videoUrl = null
          vm.frame = ''
          vm.fileRecords = []
          vm.fileRecordsForUpload = []
          vm.fileRecordsForPoster = []
          vm.fileRecordsForPosterForUpload = []

          vm.videoEditForm.title_en = ''
          vm.videoEditForm.title_ru = ''
          vm.videoEditForm.title_uz = ''
        })
        .catch((err) => {
          dispatch('auth/setRequestError', { err }, { root: true })
        })
    }
  }
}
