import axiosApiInstance from '@/api/axios'

const state = {
  photoAlbumForGallery: [],
  photoGalleryLoading: false
}

const getters = {
  photoAlbumForGallery: (state) => state.photoAlbumForGallery,
  photoGalleryLoading: (state) => state.photoGalleryLoading
}

const mutations = {
  setPhotoGalleryLoading(state, payload) {
    state.photoGalleryLoading = payload
  },
  setPhotoAlbumForGallery(state, payload) {
    state.photoAlbumForGallery = payload
  }
}

const actions = {
  fetchPhotoAlbumForGallery({ commit, dispatch }) {
    commit('setPhotoGalleryLoading', true)
    axiosApiInstance
      .get('/api/photos_album/list_without_pagination')
      .then((res) => {
        commit('setPhotoAlbumForGallery', res.data)
      })
      .catch((err) => {
        dispatch('auth/setRequestError', { err }, { root: true })
      })
      .finally(() => {
        setTimeout(() => {
          commit('setPhotoGalleryLoading', false)
        }, 500)
      })
  },

  createPhotoGallery({ dispatch }, { vm, payload }) {
    const data = new FormData()

    for (const key in payload) {
      data.append(key, payload[key])
    }

    axiosApiInstance
      .post('/api/photos/create', data)
      .then(() => {
        vm.$bvModal.hide('createPhotoGalleryModal')
        vm.$emit('update')

        vm.photoAlbum_id = null
        vm.fileRecords = []
        vm.fileRecordsForUpload = []
      })
      .catch((err) => {
        dispatch('auth/setRequestError', { err }, { root: true })
      })
  },

  // delete photo gallery
  deletePhotoGallery({ dispatch }, { vm, id }) {
    const data = new FormData()

    data.append('id', id)

    axiosApiInstance
      .post('/api/photos/delete', data)
      .then(() => {
        vm.$emit('update')
      })
      .catch((err) => {
        dispatch('auth/setRequestError', { err }, { root: true })
      })
  },

  // get photo gallery for edit
  fetchSinglePhotoGallery({ dispatch }, { vm, id }) {
    axiosApiInstance
      .get('/api/photos/edit', {
        params: {
          id
        }
      })
      .then((res) => {
        vm.photoAlbum_id = res.data.photo.album_id
        vm.imgUrl = res.data.photo.path
      })
      .catch((err) => {
        dispatch('auth/setRequestError', { err }, { root: true })
      })
  },

  editPhotoGallery({ dispatch }, { vm, payload, id }) {
    const data = new FormData()

    for (const key in payload) {
      data.append(key, payload[key])
    }

    data.append('id', id)

    axiosApiInstance
      .post('/api/photos/update', data)
      .then(() => {
        vm.$bvModal.hide(`editPhotoGallery${vm.uniqueId}Modal`)
        vm.$emit('update')

        vm.photoAlbum_id = null
        vm.fileRecords = []
        vm.fileRecordsForUpload = []
        vm.imgUploded = false
        vm.imgUrl = null
      })
      .catch((err) => {
        dispatch('auth/setRequestError', { err }, { root: true })
      })
  }
}

export default {
  state,
  getters,
  mutations,
  actions
}
